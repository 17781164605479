
.page-confirm {
  .address {
    margin: 15px 15px 0 15px;
    min-height: 70px;
    background: #ffffff;
    border-radius: 8px;
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .empty {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      .icon-add {
        display: block;
        width: 18px;
        height: 18px;
        margin-right: 5px;
        background: url(../../assets/icons/icon-add.png) center/100%,
          100% no-repeat;
      }
      span {
        font-size: 17px;
        color: #333333;
        font-weight: bold;
        line-height: 24px;
      }
    }
    .tit {
      font-size: 15px;
      font-weight: bold;
      line-height: 21px;
      width: 298px;
    }
    .txt {
      font-size: 14px;
      color: #666666;
      line-height: 20px;
      margin-top: 8px;
    }
    .icon-right {
      display: block;
      width: 14px;
      height: 14px;
      background: url(../../assets/icons/icon-right.png) center/100%,
        100% no-repeat;
    }
  }
  .kech {
    margin: 12px 15px 0 15px;
    background: #ffffff;
    border-radius: 8px;
    padding: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .img {
      width: 70px;
      height: 70px;
      background: linear-gradient(180deg, #ee9183 0%, #ffcab7 100%);
      border-radius: 4px;
    }
    .rt {
      height: 70px;
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: flex-start;
      padding-left: 10px;
    }
  }
  .bt-box {
    background: #ffffff;
    border-radius: 8px;
    font-size: 13px;
    margin: 12px 15px 0 15px;
    .item {
      height: 45px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 15px;
      .lt-tit {
        font-size: 14px;
        font-weight: bold;
        line-height: 20px;
      }

      .rt {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .icon-pay {
          display: block;
          width: 16px;
          height: 16px;
          margin-right: 5px;
        }
        .icon-wx {
          background: url(../../assets/image/pay/icon-wx.png) center/100%,
            100% no-repeat;
        }
        .icon-zfb {
          background: url(../../assets/image/pay/icon-zfb.png) center/100%,
            100% no-repeat;
        }
        .icon-edit {
          background: url(../../assets/image/pay/icon-edit.png) center/100%,
            100% no-repeat;
        }
      }
      .ipt {
        text-align: end;
      }
      .phone_ipt {
        width: 84px;
      }
    }
  }
  .bt-protocol {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    position: fixed;
    bottom: 62px;
    left: 0;
    right: 0;
    font-size: 12px;
    color: #333333;
    padding-left: 16px;
    .icon-selected {
      display: block;
      width: 14px;
      height: 14px;
      background: url(../../assets/icons/icon-selected.png) center/100%,
        100% no-repeat;
      margin-right: 4px;
    }
    .icon-unselected {
      display: block;
      width: 14px;
      height: 14px;
      background: url(../../assets/icons/icon-unselected.png) center/100%,
        100% no-repeat;
      margin-right: 4px;
    }
  }
  .footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    .foot {
      width: 100%;
      height: 58px;
      background: #ffffff;
      box-shadow: 0px -1px 4px 0px rgba(69, 69, 69, 0.05);
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 16px;
    }
  }
  .pop-code {
    width: 350px;
    height: 400px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    .title {
      padding: 15px 0 30px 0;
    }
    .btn {
      margin-top: 30px;
    }
  }
  .get-code {
    float: right;
    margin: 12px 16px 0 0;
    .obtain-code {
      height: 20px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #fe5c32;
      line-height: 20px;
    }
    .obtain-code01 {
      opacity: 0.34;
    }
  }
}
